/* Common Imports */

import React from "react";

/* Component Imports */

import Layout from "../components/layout-components/Layout";
import ContactUs from "../components/page-sections/index/ContactUs";
import OriginSection from "../components/page-sections/index/OriginSection";
import PropertyImage from "../components/page-sections/index/PropertyImage";
import SectionFour from "../components/page-sections/index/SectionFour";
import SectionOneNew from "../components/page-sections/index/SectionOneNew";
import SectionThree from "../components/page-sections/index/SectionThree";
import SectionTwo from "../components/page-sections/index/SectionTwo";

/* Session Imports */

import { withIronSessionSsr } from "iron-session/next";
import { sessionOptions } from "../lib/session";

const Index = ({ user }) => {
  return (
    <React.Fragment>
      <Layout backgroundPlaceholder={false} noContainer user={user}>
        <PropertyImage />
        <SectionOneNew />
        <SectionTwo />
        <SectionThree />
        <OriginSection />
        <SectionFour />
        <ContactUs />
      </Layout>
    </React.Fragment>
  );
};

export default Index;

// eslint-disable-next-line space-before-function-paren
export const getServerSideProps = withIronSessionSsr(async function ({
  req,
  res,
}) {
  /* Getting user session cookie */
  const user = await req.session.user;

  /* User Authorization */
  if (user == undefined) {
    return {
      props: {
        user: { isLoggedIn: false },
      },
    };
  } else {
    /* Pass data to the page via props */
    return {
      props: { user: req.session.user },
    };
  }
},
sessionOptions);
